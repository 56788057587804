
<template>
  <main class="d-flex align-items-center">
    <div class="container mt-3 p-3 text-center">
      <h1>404</h1>
      <h2>Oops! Page not found</h2>
      <router-link to="/" class="btn bg-p ms-2 mt-4">Back to Home</router-link>
    </div>
  </main>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
h1 {
  font-size: 100px;
}
</style>
